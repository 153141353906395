import React from "react";
import {
  Box,
  Container,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";

export default function SmallWithLogoLeft() {
  return (
    <Box
      bg={useColorModeValue("brand.50", "brand.900")}
      color={useColorModeValue("brand.700", "brand.200")}
    >
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        direction={{ base: "column", md: "row" }}
        spacing={4}
        justify={{ base: "center", md: "space-between" }}
        align={{ base: "center", md: "center" }}
      >
        <Box w={40}>
          <StaticImage
            src="../images/profilerlive-logo.png"
            alt="ProfilerLive Logo in White"
          />
        </Box>
        <Text>© 2025 ProfilerLive. All rights reserved. <small><a href="https://files.profilerlive.com/SaaS_Terms_Conditions_April_2023.pdf" target="_blank">SaaS Terms & Conditions</a></small></Text>
      </Container>
    </Box>
  );
}
